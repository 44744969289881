import React from 'react';
export const TranslationContext = React.createContext();

export const translations = {
  en: {
    currentLang: 'En',
    introTitle: 'Hello! Let’s get to know each other :)',
    introText:
      'My name is Ava. I help people by providing them with crisis self-help practices.',
    introQuestion: 'What’s your name?',
    introPlaceholder: 'My name is...',
    greetingsTitle: 'Nice to meet you, ',
    exclamation: '!',
    greetingsText: `I want you to know that I'm an artificial intelligence. Not a very complex one but specialized. I’ve studied the works of psychologists and now I can help people to cope with difficulties in crisis times.`,
    greetingsQuestion: `Tell me about yourself and you. What do you do?`,
    greetingsPlaceholder: 'Occupation',
    conditionTitle: 'What could I help you with?',
    conditionText:
      "It's simple with me: you answer my questions, and I learn to offer the best solutions specifically for your situation. Let's try it. What would you like to get my help on?",
    conditionQuestion: `Or write down what’s bothering you:`,
    conditionPlaceholder: `I'm worried about...`,
    conditionFear: `I’m scared`,
    conditionBad: 'Not feeling well',
    conditionTerrible: 'Terrible mood',
    conditionConfusion: `I don’t know what to do`,
    conditionAnxiety: 'Anxiously',
    conditionPanic: 'Panic attack',
    conditionProductivity: 'Productivity problem',
    mailTextStart: ', this is our ',
    botLink: 'telegram chat-bot',
    mailTextMiddle: '.',
    mailTextEnd:
      'Soon I will have a mobile app with both crisis solutions and practices for everyday life. Leave your email and I’ll invite you to become an early bird user!',
    mailPlaceholder: 'Email',
    thanksTextStart: 'Here you can find information about our project:',
    thanksTextMiddle: '. There are some other useful materials for you. My ',
    thanksTeamLink: 'team’s video',
    thanksPresentationLink: 'Presentation',
    thanksBot: ' about the project. And once again the link to the ',
    thanksTextEnd: `Please share the info about me with your friends. It would be great if I could help them too! ;)`,
    videoText: `, it’s great if AVA sparked your interest. We do our best to help people cope with psychological difficulties by themselves. In the current situation we work as a large team to support people in crisis during the war in Ukraine.`,
    videoTitleEnd: 'We believe AVA can be useful.',
    contactsTitle: 'Let me introduce you, this is my team',
    contactsZeldinName: 'Misha Zeldin',
    contactsZeldinRole: 'Gandalf',
    contactsMatveevName: 'Vitaliy Tevelev',
    contactsMatveevRole: 'Psychology',
    contactsRozovskiName: 'Iliya Rozovsky',
    contactsRozovskiRole: 'Product dev',
    contactsText:
      'And psychologists, programmers and many other wonderful people!',
    contactsAddressTitle: 'Address',
    contactsAddress: 'Brodetsky 36, 406; Ramat Aviv, Tel Aviv, Israel. 6905155',
    footerPresentation: 'Presentation',
    footerContacts: 'Contacts',
    footerTelegram: 'Telegram',
    turnBackButton: 'Go back',
    popupMainPage: 'To main',
  },
  ru: {
    currentLang: 'Ru',
    introTitle: 'Привет! Давай знакомиться :)',
    introText:
      'Меня зовут AVA. Я помогаю людям - знакомлю их с практиками кризисной самопомощи.',
    introQuestion: 'А как тебя зовут?',
    introPlaceholder: 'Меня зовут...',
    greetingsTitle: 'Рада познакомиться, ',
    exclamation: '!',
    greetingsText:
      'Хочу чтобы ты знал, что я –  искусственный интеллект, пока не очень сложный, но зато специализированный. Я обучалась на научных исследованиях психологов, и теперь помогаю людям справляться с трудностями в кризисные моменты жизни.',
    greetingsQuestion: `Расскажи о себе и ты. Чем занимаешься?`,
    greetingsPlaceholder: 'Профессия',
    conditionTitle: 'Как думаешь, с чем я могла бы помочь тебе?',
    conditionText:
      'Со мной просто: ты отвечаешь на мои вопросы или знакомишься с информацией, а я учусь подбирать наилучшие решения специально для твоей ситуации. Давай попробуем прямо сейчас. С чем из перечисленного ты хотел бы поработать?',
    conditionQuestion: 'Или напиши что тебя беспокоит',
    conditionPlaceholder: 'Написать',
    conditionFear: 'Мне страшно',
    conditionTired: 'Устал от работы',
    conditionBad: 'Плохо себя чувствую',
    conditionTerrible: 'Ужасное настроение',
    conditionConfusion: 'Не знаю, что делать',
    conditionAnxiety: 'Тревожно',
    conditionPanic: 'Паническая атака',
    conditionProgress: 'Хочу развиваться',
    conditionUnderstand: 'Хочу лучше понять себя',
    conditionRelationship: 'Хочу здоровых отношений',
    conditionProductivity: 'Проблемы с продуктивностью',
    conditionColleagues: 'Это подойдет моим коллегам',
    mailTextStart: ', это наш чат-бот в ',
    botLink: 'телеграмe',
    mailTextMiddle: '.',
    mailTextEnd:
      'Скоро у меня выйдет и приложение не только с кризисными, но и полезными для повседневной жизни практиками. Оставь email и я пришлю приглашение стать одним из моих первых пользователей!',
    mailPlaceholder: 'Почта',
    thanksTitle: 'Спасибо за разговор, ',
    thanksExclamation: '!',
    thanksTextStart: 'Здесь ты сможешь найти информацию о нашем проекте: ',
    thanksTeamLink: 'Обращение ',
    thanksTeamEnd: ' моей команды',
    thanksPresentationLink: ' Презентация',
    thanksPresentationEnd: ' о проекте, наши ',
    thanksContacts: 'контакты',
    thanksBot: '. И ещё раз ссылка на ',
    thanksBotEnd: ', заходи попробовать!',
    thanksTextEnd:
      'Расскажи обо мне друзьям и знакомым! Будет здорово, если буду им полезна! ;)',
    videoText:
      ', классно, что тебя заинтересовала AVA. Мы работаем над тем, чтобы люди могли справляться с психологическими трудностями сами. В нынешних обстоятельствах мы с большой командой прикладываем все усилия, чтобы поддержать людей в кризисе из-за войны в Украине.',
    videoTitleEnd:
      'Верим AVA сможет быть полезна многим и в мирное время тоже.',
    contactsTitle: 'Позволь познакомить, это моя команда',
    contactsZeldinName: 'Миша Зельдин',
    contactsZeldinRole: 'Гендальф',
    contactsMatveevName: 'Виталий Тевелев',
    contactsMatveevRole: 'Психология',
    contactsRozovskiName: 'Илья Розовский',
    contactsRozovskiRole: 'Продукт',
    contactsText:
      'А ещё психологи, программисты и много других замечательных людей!',
    contactsAddressTitle: 'Адрес',
    contactsAddress:
      'Бродецки 36, 406; Рамат–Авив, Тель-Авив, Израиль. 6905155',
    footerPresentation: 'Презентация',
    footerContacts: 'Контакты',
    footerTelegram: 'Телеграм',
    turnBackButton: 'Вернуться назад',
    popupMainPage: 'На главную',
  },
  uk: {
    currentLang: 'Uk',
    introTitle: 'Вітаю! Давай знайомитися.',
    introText:
      'Мене звати AVA. Я допомагаю людям — знайомлю їх із засобами кризисної самодопомоги.',
    introQuestion: 'Як тебе звати?',
    introPlaceholder: 'Мене звати...',
    greetingsTitle: 'Радий знайомству, ',
    exclamation: '!',
    greetingsText:
      'Хочу, щоб ти знав, що я — штучний інтелект, поки не дуже складний, проте спеціалізований. Я навчалася на наукових дослідженнях психологів, і тепер допомагаю людям долати труднощі в кризові моменти життя.',
    greetingsQuestion: `Розкажи про себе. Чим займаєшся?`,
    greetingsPlaceholder: 'Зайнятість',
    conditionTitle: 'Як вважаєш, з чим би я могла допомогти тобі?',
    conditionText:
      'Зі мною просто: ти відповідаєш на мої питання чи знайомишся з інформацією, а я навчаюсь підібрати якомога кращі рішення спеціально для твоєї ситуації. Давай спробуємо саме зараз. З чим із переліченого ти хотів би попрацювати?',
    conditionQuestion: 'Напиши, що тебе непокоїть',
    conditionPlaceholder: 'Мене непокоїть...',
    conditionFear: 'Мені страшно',
    conditionBad: 'Погано себе почуваю',
    conditionTerrible: 'Жахливий настрій',
    conditionConfusion: 'Не розумію, що робити далі',
    conditionAnxiety: 'Тривожно',
    conditionPanic: 'Панічні атаки',
    conditionProductivity: 'Проблеми з продуктивністю',
    mailTextStart: ', , це наш чат-бот в ',
    botLink: 'Телеграмі',
    mailTextMiddle: '.',
    mailTextEnd:
      'Незабаром у мене вийде ще додаток, через який ми зможемо спілкуватися хоч щодня. Залиш свій email, я надішлю тобі запрошення стати одним з перших моїх користувачів!',
    mailPlaceholder: 'Пошта',
    thanksTextStart: 'Тут ти можеш знайти інформацію про наш проєкт:',
    thanksTextMiddle:
      '. Тут ще зібрані деякі корисні матеріали для тебе. Звернення моєї ',
    thanksTeamLink: 'команди',
    thanksPresentationLink: 'Презентація ',
    thanksBot: 'проєкту, i ще раз посилання на ',
    thanksTextEnd:
      'Розповідай про мене друзям та знайомим! Буде чудово, якщо буду їм корисна! ;)',
    videoText:
      ', класно, що тебе зацікавила AVA. Ми працюємо над тим, щоб люди відчували себе щасливішими. У нинішніх обставинах ми великою командою докладаємо всіх зусиль, щоб підтримати людей у кризі під час війни в Україні. ',
    videoTitleEnd:
      'Сподіваємося, що AVA зможе допомогти багатьом і в мирний час також.',
    contactsTitle: 'Дозволь познайомити, це моя команда',
    contactsZeldinName: 'Михайло Гіпсман',
    contactsZeldinRole: 'Гендальф',
    contactsMatveevName: 'Віталій Тевелєв',
    contactsMatveevRole: 'Психологія',
    contactsRozovskiName: `Іл'я Розовскій`,
    contactsRozovskiRole: 'Розвиває продукт',
    contactsText:
      'А ще психологи, програмісти та ще багато інших чудових людей!',
    contactsAddressTitle: 'Адреса',
    contactsAddress:
      'Бродецкі 36, 406; Рамат–Авів, Тель-Авів, Ізраїль. 6905155',
    footerPresentation: 'Презентація',
    footerContacts: 'Контакти',
    footerTelegram: 'Telegram',
    turnBackButton: 'Повернутися назад',
    popupMainPage: 'На головну',
  },
};
